.nav-menu-icon {
  color: #0079c1 !important;
  font-size: 18px;

  text-align: center;
  display: inline-block;

  &.fa {
    font-size: 16px;
  }

  &.icon_main_32 {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/main_icon_32.png);
  }
  &.user_set_icon_32 {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/user_set_icon_32.png);
  }

  &.dataanalysis_icon_32 {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/dataanalysis_icon_32.png);
  }
  &.icon-geofence {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/geofance.png);
  }
  &.icon-rdm-manage {
    width: 14px;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/RDM_manage.png);
  }
  &.icon-package {
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/device_package.png);
  }
  &.icon-fault {
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/fault_manage.png);
    color: #0079c1 !important;
  }
  &.icon-factory {
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/factory.png);
  }
  &.icon-machine-type {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/machine_type.png);
  }
  &.icon-machine {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/car-fill.png);
  }
  &.icon-home {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/home.png);
  }
  &.icon_user_32 {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/user_set_icon_32.png);
  }
  &.icon_note {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./image/menu_icon/notificaion.png);
  }
  &.el-icon-crop
  {
    font-size: 16px;
  }
}

.page {
  padding: 0px 0px;
  .page-title {
    text-align: left;
    font-size: 13px;
    margin: 5px 5px;
    padding-bottom: 5px;
    border-bottom: solid 1px rgb(224, 222, 222);
  }
  .page-contents {
  }
}
