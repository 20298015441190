.nav-menu-icon {
  color: #0079c1 !important;
  font-size: 18px;
  text-align: center;
  display: inline-block;
}
.nav-menu-icon.fa {
  font-size: 16px;
}
.nav-menu-icon.icon_main_32 {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/main_icon_32.png);
}
.nav-menu-icon.user_set_icon_32 {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/user_set_icon_32.png);
}
.nav-menu-icon.dataanalysis_icon_32 {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/dataanalysis_icon_32.png);
}
.nav-menu-icon.icon-geofence {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/geofance.png);
}
.nav-menu-icon.icon-rdm-manage {
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/RDM_manage.png);
}
.nav-menu-icon.icon-package {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/device_package.png);
}
.nav-menu-icon.icon-fault {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/fault_manage.png);
  color: #0079c1 !important;
}
.nav-menu-icon.icon-factory {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/factory.png);
}
.nav-menu-icon.icon-machine-type {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/machine_type.png);
}
.nav-menu-icon.icon-machine {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/car-fill.png);
}
.nav-menu-icon.icon-home {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/home.png);
}
.nav-menu-icon.icon_user_32 {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/user_set_icon_32.png);
}
.nav-menu-icon.icon_note {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./image/menu_icon/notificaion.png);
}
.nav-menu-icon.el-icon-crop {
  font-size: 16px;
}
.page {
  padding: 0px 0px;
}
.page .page-title {
  text-align: left;
  font-size: 13px;
  margin: 5px 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px #e0dede;
}
